@font-face {
  font-family: 'helvetica_now_display';
  src: url('helveticanowdisplay-light-webfont.woff2') format('woff2'),
       url('helveticanowdisplay-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;

}




@font-face {
  font-family: 'helvetica_now_display';
  src: url('helveticanowtext-light-webfont.woff') format('woff2'),
       url('helveticanowtext-light-webfont.woff2') format('woff');
  font-weight: 500;
  font-style: normal;

}




@font-face {
  font-family: 'helvetica_now_text';
  src: url('helveticanowtext-light-webfont.woff2') format('woff2'),
       url('helveticanowtext-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;

}
