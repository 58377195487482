
@font-face {
  font-display: swap;
  font-family: 'Roboto Condensed';
  src: url('robotocondensed-bold-webfont.woff2') format('woff2'),// main font file
       url('robotocondensed-bold-webfont.woff') format('woff'); // fallback, both should cover every browser
  font-weight: 700;
  font-style: normal;

}

@font-face {
  font-display: swap;
  font-family: 'Roboto Condensed';
  src: url('robotocondensed-medium-webfont.woff2') format('woff2'),// main font file
       url('robotocondensed-medium-webfont.woff') format('woff'); // fallback, both should cover every browser
  font-weight: 500;
  font-style: normal;

}




@font-face {
  font-display: swap;
  font-family: 'Roboto Condensed';
  src: url('robotocondensed-regular-webfont.woff2') format('woff2'),// main font file
       url('robotocondensed-regular-webfont.woff') format('woff'); // fallback, both should cover every browser
  font-weight: 400;
  font-style: normal;

}
