.loading {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 5px;

  &:focus {
    outline: none;
  }

  &.hide {
    display: none;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  .message {
    position: absolute;
    top: 90px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    padding: 5px 15px;
    border-radius: 5px;
    text-align: center;

    &.isMobile {
      width: 300px;
      white-space: pre-wrap;
    }
  }
}
