@font-face {
  font-family: 'Calibri';
  src: url('Calibri-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('Calibri-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Calibri';
  src: url('Calibri-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('Calibri-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('Calibri-Bold-Italic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
}
