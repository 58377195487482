@font-face {
  font-family: 'CrimsonPro';
  src: url('CrimsonPro-Black.woff2') format('woff2'),
       url('Crimsonpro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'CrimsonPro';
  src: url('CrimsonPro-Bold.woff2') format('woff2'),
       url('Crimsonpro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'CrimsonPro';
  src: url('CrimsonPro-ExtraBold.woff2') format('woff2'),
       url('Crimsonpro-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'CrimsonPro';
  src: url('CrimsonPro-ExtraLight.woff2') format('woff2'),
       url('Crimsonpro-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'CrimsonPro';
  src: url('CrimsonPro-Italic.woff2') format('woff2'),
       url('Crimsonpro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'CrimsonPro';
  src: url('CrimsonPro-Light.woff2') format('woff2'),
       url('CrimsonPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'CrimsonPro';
  src: url('CrimsonPro-Medium.woff2') format('woff2'),
       url('CrimsonPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CrimsonPro';
  src: url('CrimsonPro-Regular.woff2') format('woff2'),
       url('CrimsonPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CrimsonPro';
  src: url('CrimsonPro-SemiBold.woff2') format('woff2'),
       url('CrimsonPro-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('Nexa-ExtraLight.woff2') format('woff2'),
       url('Nexa-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('Nexa-Heavy.woff2') format('woff2'),
       url('Nexa-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
